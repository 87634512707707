import React from "react";
import { graphql } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

export default function Template({data}) {

    let page = data.markdownRemark
    const frontmatter = page.frontmatter
    const featuredImgFluid = frontmatter.featuredImage.childImageSharp.gatsbyImageData
    const content = page.html

    return (
        <Layout currentPath={frontmatter.path}>
            <Seo title={page.frontmatter.title} />
            <article>
                <div className="o-section">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-md-9 offset-md-3 u-ta:c u-ta-md:l">
                                <h1 className="u-color:dark-theme">{frontmatter.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="o-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 mx-auto mx-md-0 col-md-3 u-pr:3 u-pr-lg:6 u-mt-md:-6">
                                <GatsbyImage image={featuredImgFluid}  className="u-mt:-6" style={{borderRadius:'50%'}}/>
                            </div>
                            <div className="col-md-9 col-lg-7 col-xl-6 u-mt:3 u-mt-md:0">
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                        </div>
                    </div>
                </div>
            </article>

        </Layout>
    )
}

export const pageQuery = graphql`
    query TeacherPageQuery($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                title
                path
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(
                        width: 800
                        quality: 90
                        placeholder: BLURRED
                    )
                  }
                }
            }
        }
    }
`
